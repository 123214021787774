<template>
  <div class="chart-container">
    <div class="chart-header">{{ title }}</div>
    <!-- <div class="u-flex u-row-center">
      <slot name="select"></slot>
    </div> -->
    <div class="chart-box pl-20 pr-20">
      <slot name="chart"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartModel",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  width: 442px;
  height: 303px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  background: url("~@/assets/images/analysis/leftRight.png") no-repeat;
  background-size: 100% 100%;
  .chart-header {
    display: flex;
    justify-content: center;
    height: 56px;
    align-items: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    // text-shadow: 0px 3px 9px rgba(9, 17, 99, 0.54);
    // background: linear-gradient(0deg, #cccccc 0%, #ffffff 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .chart-box {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
