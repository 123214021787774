<template>
  <div :style="{ width: width, height: height }"></div>
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "200px",
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.chartInit();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    chartInit() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(chartData) {
      let { label, planData, actualData } = chartData;
      this.chart.setOption({
        grid: {
          top: "18%",
          left: "6%",
          right: "10%",
          bottom: "14%",
          height: "60%",
        },
        xAxis: {
          type: "category",
          data: label,
          axisLine: {
            lineStyle: {
              color: "#557DF7",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            name: "件",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#557DF7",
              },
            },
            axisLabel: {
              align: "left",
            },
            splitLine: {
              lineStyle: {
                color: "rgba(76, 112, 219, 0.3)",
                width: 1,
                opacity: 0.7,
              },
            },
            splitArea: {
              areaStyle: {
                color: "transparent",
              },
            },
          },
          {
            name: "%",
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#557DF7",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(76, 112, 219, 0.3)",
                width: 1,
                opacity: 0.7,
              },
            },
            axisLabel: {
              align: "right",
            },
            splitArea: {
              areaStyle: {
                color: "transparent",
              },
            },
          },
        ],
        series: [
          {
            name: "实际生产",
            type: "bar",
            data: planData,
            barWidth: 40,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(0, 230, 255, 1)" },
                { offset: 1, color: "rgba(0, 201, 255, 0)" },
              ]),
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#fff",
                },
              },
            },
          },
          {
            name: "计划生产",
            type: "line",
            data: actualData,
            yAxisIndex: 1,
            symbol: "emptyCircle",
            symbolSize: 8,
            color: "#FD951D",
            smooth: false,
          },
        ],
      });
    },
  },
};
</script>

<style scoped></style>
