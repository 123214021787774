<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    color: {
      type: String,
      default: "#5B8FF9",
    },
    chartData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
      interval: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(chartData = []) {
      const label = chartData.map((el) => el.Key);
      const data = chartData.map((el) => el.Value);
      const that = this;
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          height: "90%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: chartData.map((item)=>item.Key),
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              interval:0,
              width:100,
              overflow:'truncate',
              ellipsis:'...',
              color:'#fff'
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "rgba(76, 112, 219, 0.3)",
                width: 1,
                opacity: 0.7,
              },
            },
            splitArea: {
              areaStyle: {
                color: "transparent",
                opacity: 0,
              },
            },
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: "40",
            data: chartData.map((item)=>item.Value),
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(0, 230, 255, 1)" },
                { offset: 1, color: "rgba(0, 201, 255, 0)" },
              ]),
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#fff",
                },
              },
            },
          },
        ],
      };
      if (this.chart) {
        that.chart.setOption(option);
      }
      // this.interval = setInterval(() => {
      //   // 每次向后滚动一个，最后一个从头开始。
      //   if (option.dataZoom[0].endValue === label.length) {
      //     option.dataZoom[0].endValue = 4
      //     option.dataZoom[0].startValue = 0
      //   } else {
      //     option.dataZoom[0].endValue += 1
      //     option.dataZoom[0].startValue += 1
      //   }
      //   this.chart.setOption(option)
      // }, 3000)
    },
  },
};
</script>
